<div
	class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
	style="background-color: #fcf9f9;"
>
	<!-- Header -->
	<div class="login-header">
		<div class="logos-multi">
			<img src="logo1.png" alt="Logo KeOla" class="logo" />
			<img src="logo2.png" alt="Logo IncluB" class="logo" />
			<img src="logo3.png" alt="Logo InResorts" class="logo" />
		</div>
		<div class="logo-single">
			<img src="logo4.png" alt="Logo Language" class="logo" />
		</div>
	</div>

	<!-- Main Content -->
	<div class="auth-content d-flex justify-content-center align-items-center">
		<router-outlet></router-outlet>
	</div>

	<!-- Footer -->
	<footer class="page-footer">
		<div class="footer-arc">
			<p>©2024 Derechos reservados</p>
		</div>
	</footer>
</div>
