import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { environment } from '../../../environments/environment';
import { jwtDecode } from 'jwt-decode';
import { AuthModel } from '../models/auth.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private apiUrl = `${environment.apiUrl}/auth/login`;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private http: HttpClient,
    private messageService: MessageService
  ) {}

  private decodeToken(token: string): any {
    return jwtDecode(token); // Usamos la función jwtDecode
  }

  private isTokenValid(decodedToken: any): boolean {
    const currentTime = Math.floor(Date.now() / 1000); // Tiempo actual en segundos
    return decodedToken.exp > currentTime;
  }

  login(
    email: string,
    password: string,
    rolType: string
  ): Observable<AuthModel | null> {
    const body = { email, password, rolType };

    return this.http.post<AuthModel>(this.apiUrl, body).pipe(
      map((response: AuthModel) => {
        const currentTime = Math.floor(Date.now() / 1000);
        const decodedToken: any = this.decodeToken(response.token); // Deserializamos el token

        if (this.isTokenValid(decodedToken)) {
          // Validamos si el token es válido
          localStorage.setItem('access', JSON.stringify(response));
          this.messageService.add({
            severity: 'success',
            summary: 'Información',
            detail: 'Logueo exitoso',
          });
          return response;
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Información',
            detail: 'El token a expirado',
          });
          return null;
        }
      }),
      catchError((error) => {
        switch (error.status) {
          case 401:
            this.messageService.add({
              severity: 'warn',
              summary: 'Información',
              detail: 'Contraseña incorrecta',
            });
            break;
          case 403:
            this.messageService.add({
              severity: 'warn',
              summary: 'Información',
              detail: 'No tienes los permisos para acceder como administrador',
            });
            break;
          case 404:
            this.messageService.add({
              severity: 'warn',
              summary: 'Información',
              detail: 'Email no encontrado',
            });
            break;
          case 500:
            this.messageService.add({
              severity: 'warn',
              summary: 'Información',
              detail:
                'Hubo un problema en el servidor. Por favor, intenta más tarde',
            });
            break;
          default:
            this.messageService.add({
              severity: 'warn',
              summary: 'Información',
              detail:
                'Ocurrió un error inesperado. Por favor, contacta al soporte técnico',
            });
        }
        return of(null);
      })
    );
  }

  logout(): void {
    localStorage.removeItem('access');
    this.messageService.add({
      severity: 'info',
      summary: 'Información',
      detail: 'Has cerrado sesión exitosamente.',
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
