import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth.component';
import { LoginComponent } from './components/login/login.component';

const routes: Routes = [
  {
    path: 'auth', // Base path para "auth"
    component: AuthComponent,
    children: [
      {
        path: 'login', // Ruta para "auth/login"
        component: LoginComponent,
        data: { returnUrl: window.location.pathname },
      },
	//   {
    //     path: 'forgot-password', // Ruta para "auth/forgot-password"
    //     component: LoginComponent,
    //     data: { returnUrl: window.location.pathname },
    //   },
      {
        path: '**', // Redirige cualquier ruta inválida bajo "auth"
        redirectTo: 'login',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
